import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';

import BookRating from './book-rating';
import styles from '../../styles/books/book-cover.module.css';

import localized from '../../../i18n/localized.json';

function BookCover({ book, locale }) {
  const { unknownCover: { fixed } } = useStaticQuery(
    graphql`
      query {
        unknownCover: imageSharp(fixed: {originalName: {eq: "unknown-cover.png"}}) {
          fixed(height: 280, pngQuality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    `
  )
  return (
    <div className={styles.bookCover}>
      <Link to={`/books/${book.slug}`}>
        <Img fixed={book.cover?.childImageSharp?.fixed || fixed} />
        <h2>{book.title}</h2>
        <h3>{book.authors.join(', ')}</h3>
      </Link>

      <div className={styles.bookCoverDivider} />

      <BookRating rating={book.rating} />

      <div className={styles.metaLine}>{localized[locale].shelf.onShelf}: <Link to={`/books/${book.shelf}`}>{localized[locale].shelf[book.shelf]}</Link></div>
      <div className={styles.metaLine}>{localized[locale].shelf.startDate}: {book.shelf !== 'to-read' ? book.startDate : '—'}</div>
      <div className={styles.metaLine}>{localized[locale].shelf.endDate}: {book.shelf === 'read' ? book.endDate : '—'}</div>
    </div>
  );
}

export default BookCover;
