import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useLocalization } from 'gatsby-theme-i18n';

import localized from '../../../i18n/localized.json';

import styles from '../../styles/books/book-challenge.module.css';

function BookChallenge() {
  const { locale } = useLocalization();

  const { read: { group }, site: { siteMetadata: { books: { goal } } } } = useStaticQuery(
    graphql`
      query {
        read: allMdx(filter: {frontmatter: {shelf: {eq: "read"}}}) {
          group(field: frontmatter___slug) {
            edges {
              node {
                id
                frontmatter {
                  slug
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            books {
              goal
            }
          }
        }
      }
    `
  );

  return (
    <div className={styles.bookChallenge}>
      <div>{localized[locale].book.bookChallengeTitle.replace('%', goal)}</div>
      <div className={styles.bookMeter} style={{ ['--book-counter']: `"${group.length} / ${goal}"` }}>
        <div className={styles.bookProgress} style={{ ['--book-progress']: `${group.length / goal * 100}%` }} />
      </div>
      <div className={styles.bookLinks}>
        <Link to="/books" className={styles.bookButton} activeClassName={styles.activeLink}>{localized[locale].book.all}</Link>
        <Link to="/books/to-read" className={styles.bookButton} activeClassName={styles.activeLink}>{localized[locale].book.toRead}</Link>
        <Link to="/books/currently-reading" className={styles.bookButton} activeClassName={styles.activeLink}>{localized[locale].book.currentlyReading}</Link>
        <Link to="/books/read" className={styles.bookButton} activeClassName={styles.activeLink}>{localized[locale].book.read}</Link>
      </div>
    </div>
  );
}

export default BookChallenge;
